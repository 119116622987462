import {reactive, toRefs} from "vue";
import service from "@/utils/axios";
import {useRoute} from "vue-router";
import {onPageLoadingEnd, onPageLoadingStart} from "@/utils/DLoading";

export interface IHomeworkItem {
  name: string; // 学生姓名
  homeworkUrl: string | null;  //  学生作品地址
  star: number | null;      // 学生作品的星级
  integral: number | null;    //   学生积分
  id?: any;
}

export interface IHomework {
  homeworkList: Array<IHomeworkItem>;
  currentClass: number;
  workTitle: string;
  topicName: string;
}

export function homeworkBill() {

  let stateRef = reactive(<IHomework>{
    homeworkList: [],
    currentClass: 1,
    workTitle: '',
    topicName: '',

  })

  const route = useRoute();

  let refData = toRefs(stateRef)

  let getHistoryHomework = async () => {
    const r:any  = await service.get(`/dwart/an_teaching/classCourse/findLastSectionWorkByschuldeIndexId/${route.query.scheduleId}`)

    stateRef.currentClass = r.currentClass;
    stateRef.workTitle = r.workTitle;
    stateRef.topicName = r.topicName;

    stateRef.homeworkList = r.dwWorkModelList.map((v, k)=>{
      return {
        id: k,
        integral: v.integral,
        name: v.scheduleName,
        homeworkUrl: v.workUrl,
        star: v.level
      }
    })
  }

  let init = async () => {
    onPageLoadingStart()
    await getHistoryHomework()
    onPageLoadingEnd()
  }

  return {
    refData,
    init,
  }
}
