
import {defineComponent, onMounted} from 'vue'
import TimetableTitle from "@/components/timetable/TimetableTitle.vue";
import LogoutIcon from "@/components/system/LogoutIcon.vue";
import GotoLesson from "@/components/timetable/GotoLesson.vue";
import {Col, Row, Image} from "ant-design-vue";
import {homeworkBill} from "@/views/timetable/myTimetable/Homework/Homework";
import DwRate from "@/components/system/DwRate.vue";

export default defineComponent({
  name: "Homework",
  components: {
    DwRate,
    GotoLesson,
    LogoutIcon,
    TimetableTitle,
    ARow: Row,
    ACol: Col,
    AImage: Image,
    AImagePreviewGroup: Image.PreviewGroup
  },
  setup(props, context){
    let {
      refData,
      init,
    } = homeworkBill();

    onMounted(()=>{
      init();
    })
    return {
      ...refData,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
